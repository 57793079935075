<div class="container">
  <div class="d-flex flex-wrap" *ngIf="config">
    <div class="side">
      <app-introduction
        [imgs]="config.imgSrc"
        [intro]="config.intro"
        [link]="config.link"
        [button]="config.introButton"
      ></app-introduction>
    </div>
    <div class="main flex-grow-1">
      <app-sound-buttons
        *ngIf="config.buttonGroups"
        [buttonGroups]="config.buttonGroups"
      ></app-sound-buttons>
    </div>
  </div>
</div>
<app-audio-control></app-audio-control>
<app-scroll-to-top-button [bottom]="'68px'"></app-scroll-to-top-button>
