<div class="control-container">
  <i class="bi bi-volume-up"></i>
  <input
    type="range"
    min="0"
    max="1"
    step="0.01"
    [value]="volume"
    (change)="OnVolumeChange($event)"
  />
  <i
    role="button"
    class="bi bi-collection-play clickable jello-horizontal"
    (click)="OnShuffleClick()"
    *ngIf="audioService.isEmpty()"
  ></i>
  <i
    role="button"
    class="bi bi-play-fill clickable blink-2"
    (click)="OnPlayClick()"
    *ngIf="audioService.canPlay()"
  ></i>
  <i
    role="button"
    class="bi bi-pause-fill clickable blink-2"
    (click)="OnPauseClick()"
    *ngIf="audioService.canPause()"
  ></i>
  <div class="buttonContainer">
    <button
      type="button"
      class="btn btn-primary"
      *ngFor="let button of buttons().slice(); trackBy: trackById"
      (click)="buttonClick($event, button)"
      [contextMenuTrigger]="menu"
      [menuContext]="button"
    >
      {{ button.text }}
    </button>
  </div>
  <i role="button" class="bi bi-trash3 clickable" (click)="OnStopClick()"></i>
</div>
