<nav class="navbar navbar-expand-lg navbar-dark bg-primary fixed-top">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="/">
      <img src="assets/img/favicon/icon-trim.png" alt="icon" width="33.561" height="24" />
      &nbsp;
      {{ title }}
    </a>
    <div class="navbar-brand fullName" (click)="setLiveUpdate(false)">{{ fullName }}</div>
    <button
      class="navbar-toggler"
      type="button"
      aria-controls="navbar"
      aria-label="Toggle navigation"
      (click)="isCollapsed = !isCollapsed"
      *ngIf="name"
      [attr.aria-expanded]="!isCollapsed"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" *ngIf="name" [collapse]="isCollapsed">
      <div class="navbar-nav input-group" *ngIf="!window.location.pathname.endsWith('upload')">
        <div class="input-group">
          <span class="input-group-text" id="filter-text-icon"><i class="bi bi-search"></i></span>
          <input
            id="filter-text"
            onClick="this.select();"
            [(ngModel)]="filterText"
            type="text"
            placeholder="Search..."
            class="form-control"
            (change)="setFilterText(filterText)"
            (keydown.enter)="setFilterText(filterText)"
            [typeahead]="getButtonTextList()"
            [typeaheadIsFirstItemActive]="false"
            [typeaheadScrollable]="true"
            [typeaheadOptionsInScrollableView]="20"
            (typeaheadOnSelect)="setFilterText($event.value)"
            [value]="displayService.getFilterText()"
            aria-describedby="filter-text"
          />
          <button type="button" class="btn bg-transparent" style="margin-left: -40px; z-index: 100" (click)="filterText = ''; setFilterText('')">
            <i class="bi bi-x"></i>
          </button>
          <button class="btn btn-outline-light" type="submit" id="filter-text-submit">
            <i class="bi bi-funnel-fill"></i>
          </button>
        </div>
      </div>
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="name + '/upload'" queryParamsHandling="merge">{{
            '投稿' | translate
          }}</a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link text-white"
            *ngIf="router.url.includes('upload')"
            (click)="setLiveUpdate(true)"
            >{{ '待審核預覧' | translate }}</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link text-white"
            *ngIf="router.url.includes('liveUpdate')"
            (click)="setLiveUpdate(false)"
            >{{ '回正式版' | translate }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>
<!-- PlaceHolder -->
<div class="navbar navbar-brand">&nbsp;</div>
