<div class="dropdown-menu show" style="position: relative">
  <button class="dropdown-item" (click)="copyLink()">{{ '複製按鈕網址' | translate }}</button>
  <button class="dropdown-item" (click)="download()">{{ '下載' | translate }}</button>
  <div class="dropdown-divider" [class.d-none]="!this.button.source?.videoId"></div>
  <button
    class="dropdown-item"
    (click)="copyYoutubeLink()"
    [class.d-none]="!this.button.source?.videoId"
  >
    {{ '複製 Youtube 網址' | translate }}
  </button>
  <button
    class="dropdown-item"
    (click)="openSource()"
    [class.d-none]="!this.button.source?.videoId"
  >
    {{ '在 Youtube 上觀看' | translate }}
  </button>
  <div class="dropdown-divider"></div>
  <button class="dropdown-item" (click)="shareToTwitter()">
    {{ '分享到 𝕏' | translate }}
  </button>
  <button class="dropdown-item" (click)="shareToMastodon()">
    {{ '分享到 Mastodon' | translate }}
  </button>
  <button class="dropdown-item" (click)="shareToFacebook()">
    {{ '分享到 Facebook' | translate }}
  </button>
</div>
