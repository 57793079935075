<div class="container" role="main">
  <div *ngFor="let buttonGroup of buttonGroups | buttonFilter : filterText">
    <div
      class="card border-primary mb-3"
      *ngIf="buttonGroup.buttons.length !== 0"
      [attr.count]="buttonGroup.buttons.length"
    >
      <h2 class="card-header" [id]="buttonGroup.name">{{ buttonGroup.name }}</h2>
      <div class="card-body">
        <button
          type="button"
          class="btn btn-primary"
          *ngFor="let btn of buttonGroup.buttons.slice().reverse(); trackBy: trackById"
          (click)="buttonClick($event, btn)"
          [style.gridColumn]="'auto/span ' + gridColumnLen(btn.text)"
          [contextMenuTrigger]="menu"
          [menuContext]="btn"
        >
          {{ btn.text }}
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="isFilteredEmpty()">
    <div class="alert alert-warning d-flex align-items-center" role="alert">
      <i class="bi bi-exclamation-triangle-fill"></i>
      <div translate>沒有符合條件的按鈕</div>
    </div>
  </div>
</div>
