<footer>
  <div class="logo" title="Project GitHub">
    <a href="https://github.com/sound-buttons" target="_blank" rel="noopener" class="support">
      <picture>
        <source srcset="assets/img/favicon/icon-trim.avif" type="image/avif" />
        <source srcset="assets/img/favicon/icon-trim.webp" type="image/webp" />
        <img src="assets/img/favicon/icon-trim.png" alt="icon" width="100" />
      </picture>
      <p>Sound Buttons</p>
    </a>
  </div>
  <div class="license" title="Project License" (click)="agplOnClick()">
    <img src="assets/img/AGPLv3_Logo.svg" alt="agpl" />
  </div>
  <div class="badge" title="Total Clicks" (click)="badgeOnClick()">
    <div class="circle"><i class="bi bi-hand-index-thumb"></i></div>
    <div class="ribbon">{{ clicks }}</div>
  </div>
  <div class="copyright">
    <p>
      Copyright © 2021 Sound Buttons |
      <a
        [href]="'https://github.com/sound-buttons/sound-buttons/tree/' + version"
        target="_blank"
        rel="noopener"
        >v.{{ this.version | slice : 0 : 7 | uppercase }}</a
      >
      |
      <a
        href="https://github.com/sound-buttons/sound-buttons/blob/master/LICENSE"
        target="_blank"
        rel="noopener"
        class="support"
        >AGPLv3 Licensed</a
      >
      |
      <a
        href="https://github.com/sound-buttons/sound-buttons/blob/master/privacy-policy.md"
        target="_blank"
        rel="noopener"
        >Privacy Policy</a
      >
      | Total {{ clicks }} Clicks<br />
    </p>
  </div>
</footer>
