
export const environment = {
   production: true,
   google: {
      GA_TRACKING_ID: 'G-50J7YSZJ00'
   },
   api: 'https://backend.sound-buttons.click/api',
   origin: 'https://sound-buttons.click',
   version: 'b3660ba9a1b131f60e163122215c09d7f6e3839b',
   CLARITY_TRACKING_ID: 'jnas47yqmj'
};
